import axios from '@/config/httpConfig'

//仪表板文件夹列表
export function getDashboardFolderList(data) {
    return axios.post("/dashboard/getDashboardFolderList", data);
}
// 获取所仪表板列表
export function getDashboardList(data) {
    return axios.post('/dashboard/getDashboardList', data)
}

// 获取报表详细信息
export function getReportDataByDetail(data) {
    return axios.post('/report/getReportDataByDetail', data)
}
// 获取报表文件夹
export function getFolder(data) {
    return axios.post('/report/getReportFolders', data)
}
// 获取角色树和筛选器接口
export function getRoleTreeAndDurationList(data) {
    return axios.post('/report/getRoleTreeAndDurationList', data)
}
//  获取报表数据（接口拆分需求）
export function getReportData(data) {
    return axios.post('/report/getReportData', data)
}
// 根据对象来获取全部字段
export function getObjectAllField(data) {
    return axios.post('/report/getObjectAllField', data)
}

// 优化后的报表详情查询
export function getOptimizationReportDataByDetail(data) {
    return axios.post('/report/getOptimizationReportDataByDetail', data)
}
//已有报表进行编辑时根据报表Id获取筛选条件字段列表 
export function getConditionsByReportId(data) {
    return axios.post('/report/getConditionsByReportId', data)
}
// 获取图表信息
export function getChartDataByReport(data) {
    return axios.post('/chart/getChartDataByReport', data)
}
// 获取相关对象列表
export function getRelateObjectList(data) {
    return axios.post('/object/getRelateObjectList', data)
}
// 获取主对象列表
export function getObjectList(data) {
    return axios.post('/object/getObjectList', data)
}
// 获取报表首页列表
export function getDataList(data) {
    return axios.post('/report/getReportList', data)
}
//报表文件夹置顶和取消置顶接口
export function topFolder(data) {
    return axios.post('/report/topFolder', data)
}
//设置报表列表列
export function setReportListCols(data) {
    return axios.post('/report/setReportListCols', data)
}
// 获取矩阵报表内部单元格详情信息
export function getReportDetail(data) {
    return axios.post('/report/getReportDetail', data)
}
// 保存报表
export function saveReportData(data) {
    return axios.post('/report/saveReport', data)
}
//获取报表持续时间
export function getReportDurationList(data) {
    return axios.post('/report/getReportDurationList', data)
}
//获取当前人的角色及下属角色树
export function getRoleTree(data) {
    return axios.post('/report/getRoleTree', data)
}
/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}