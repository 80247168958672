<template>
  <div class="dataSource">
    <div class="dataSource_top">
      <div class="left_title">
        <div class="title_word">{{ $t("label.selectreporttype") }}</div>
        <div class="all_types">
          <el-radio v-model="form.type" label="1">{{
            $t("vue_label_report_four-levelseriesobjecttree")
          }}</el-radio>
          <!-- <el-radio v-model="form.type" label="2">两级散点式对象树</el-radio> -->
          <el-radio v-model="form.type" label="2">
            {{ $t("label.same.month.report") }}
          </el-radio>
        </div>
      </div>
      <div class="right_butts">
        <el-button
          type="primary"
          size="mini"
          @click="clickNext"
          :disabled="form.firstChoose === ''"
          >{{ $t("UG_Forcast_09") }}</el-button
        >
        <el-button size="mini" @click="clickCancel">{{
          $t("button_partner_cancel")
        }}</el-button>
      </div>
    </div>
    <div class="dataSource_content">
      <div class="top_figure" v-if="form.firstChoose !== '' && form.type == 1">
        <svg class="icon" aria-hidden="true" slot="reference">
          <use :href="'#icon-' + seriesImg"></use>
        </svg>
      </div>
      <!-- 下拉选项的div -->
      <div class="choose_selects">
        <div class="choose_selects_div">
          <div class="choose_selects_center">
            <!-- 第一个选择 -->
            <div class="each_select">
              <div class="second_option">
                <el-select
                  v-model="form.firstChoose"
                  clearable
                  filterable
                  @clear="getClearValue('first')"
                  :placeholder="$t('label.customobjects.majorobject')"
                  @change="clickFirst"
                  @click.native="selectClick"
                >
                  <el-input
                    v-model="searchVal"
                    @input="searchValChangeEvent"
                    @focus="focusStatus(1)"
                    class="searchDataSource"
                    :placeholder="$t('label.quickbooks.searchs')"
                  >
                    <i
                      slot="suffix"
                      class="iconfont icon-sousuo search-icon el-input__icon"
                    ></i>
                  </el-input>
                  <el-option
                    v-for="(item, index) in isUseTempsearchStatus
                      ? TempsearchArr1
                      : firstChooses"
                    :key="index"
                    :label="item.labelname"
                    :value="item.id"
                    :disabled="item.disabled"
                    class="selectArea-ul"
                  ></el-option>
                </el-select>
              </div>
              <div
                class="right_line"
                v-show="
                  form.firstChoose !== '' &&
                  form.firstChoose !== '201603100108754gRole' &&
                  form.type == 1
                "
              ></div>
            </div>
            <!-- 第二个选择 -->
            <div
              class="each_select"
              v-if="
                form.firstChoose !== '' &&
                form.firstChoose !== '201603100108754gRole' &&
                form.type == 1
              "
            >
              <div class="second_option">
                <el-select
                  v-model="form.secondChoose"
                  :placeholder="$t('label.selectobject')"
                  clearable
                  filterable
                  @clear="getClearValue('second')"
                  @change="clickSecond"
                  :class="{ activeSelect: form.secondChoose !== '' }"
                  @click.native="selectClick"
                >
                  <el-input
                    v-model="searchVal"
                    @input="searchValChangeEvent"
                    @focus="focusStatus(2)"
                    class="searchDataSource"
                    :placeholder="$t('label.quickbooks.searchs')"
                  >
                    <i
                      slot="suffix"
                      class="iconfont icon-sousuo search-icon el-input__icon"
                    ></i>
                  </el-input>
                  <el-option-group
                    v-for="group in isUseTempsearchStatus
                      ? TempsearchArr2
                      : secondChooses"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="(item, index) in group.options"
                      :key="index"
                      :label="`${item.labelname} (${item.fieldlabel})`"
                      :value="item.fieldid"
                      :disabled="item.disabled"
                    ></el-option>
                  </el-option-group>
                </el-select>

                <div
                  class="choose_relationship"
                  v-show="form.secondChoose !== ''"
                >
                  <div>
                    {{ $t("vue_label_report_superiortothisrelationship") }}
                  </div>
                  <div class="all_radios">
                    <el-radio
                      v-model="form.relationship2"
                      :label="index"
                      v-for="(item, index) in words"
                      :key="index"
                      @change="changeWord2"
                      >{{ item }}</el-radio
                    >
                  </div>
                </div>
              </div>
              <div
                class="right_line"
                v-show="
                  form.secondChoose !== '' &&
                  form.secondChoose !== 'ffe2020925opmuf105' &&
                  !isTaskEventFiled(form.secondChoose)
                "
              ></div>
            </div>
            <!-- 第三个选择 -->
            <div
              class="each_select"
              v-if="
                form.firstChoose !== '' &&
                form.firstChoose !== '201603100108754gRole' &&
                form.secondChoose !== '' &&
                form.secondChoose !== 'ffe2020925opmuf105' &&
                !isTaskEventFiled(form.secondChoose) &&
                form.type == 1
              "
            >
              <div class="second_option">
                <el-select
                  v-model="form.thirdChoose"
                  clearable
                  filterable
                  :placeholder="$t('label.selectobject')"
                  :class="{ activeSelect: form.thirdChoose !== '' }"
                  @clear="getClearValue('third')"
                  @change="clickThird"
                  @click.native="selectClick"
                >
                  <el-input
                    v-model="searchVal"
                    @input="searchValChangeEvent"
                    @focus="focusStatus(3)"
                    class="searchDataSource"
                    :placeholder="$t('label.quickbooks.searchs')"
                  >
                    <i
                      slot="suffix"
                      class="iconfont icon-sousuo search-icon el-input__icon"
                    ></i>
                  </el-input>
                  <el-option-group
                    v-for="group in isUseTempsearchStatus
                      ? TempsearchArr3
                      : thirdChooses"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="(item, index) in group.options"
                      :key="index"
                      :label="`${item.labelname} (${item.fieldlabel})`"
                      :value="item.fieldid"
                      :disabled="item.disabled"
                    ></el-option>
                  </el-option-group>
                </el-select>
                <div
                  class="choose_relationship"
                  v-show="form.thirdChoose !== ''"
                >
                  <div>
                    {{ $t("vue_label_report_superiortothisrelationship") }}
                  </div>
                  <div class="all_radios">
                    <el-radio
                      v-model="form.relationship3"
                      :label="index"
                      v-for="(item, index) in words"
                      :key="index"
                      @change="changeWord3"
                      >{{ item }}</el-radio
                    >
                  </div>
                </div>
              </div>
              <div
                class="right_line"
                v-show="
                  form.thirdChoose !== '' &&
                  form.thirdChoose !== 'ffe2020925opmuf105' &&
                  !isTaskEventFiled(form.thirdChoose)
                "
              ></div>
            </div>
            <!-- 第四个选择 -->
            <div
              class="each_select"
              v-if="
                form.firstChoose !== '' &&
                form.firstChoose !== '201603100108754gRole' &&
                form.secondChoose !== '' &&
                form.secondChoose !== 'ffe2020925opmuf105' &&
                form.thirdChoose !== '' &&
                form.thirdChoose !== 'ffe2020925opmuf105' &&
                !isTaskEventFiled(form.thirdChoose) &&
                form.type == 1
              "
            >
              <div class="second_option">
                <el-select
                  v-model="form.fourthChoose"
                  clearable
                  filterable
                  :placeholder="$t('label.selectobject')"
                  :class="{ activeSelect: form.fourthChoose !== '' }"
                  @change="clickFourth"
                  @click.native="selectClick"
                >
                  <el-input
                    v-model="searchVal"
                    @input="searchValChangeEvent"
                    @focus="focusStatus(4)"
                    class="searchDataSource"
                    :placeholder="$t('label.quickbooks.searchs')"
                  >
                    <i
                      slot="suffix"
                      class="iconfont icon-sousuo search-icon el-input__icon"
                    ></i>
                  </el-input>
                  <el-option-group
                    v-for="group in isUseTempsearchStatus
                      ? TempsearchArr4
                      : fourthChooses"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.id"
                      :label="`${item.labelname} (${item.fieldlabel})`"
                      :value="item.fieldid"
                      :disabled="item.disabled"
                    ></el-option>
                  </el-option-group>
                </el-select>
                <div
                  class="choose_relationship"
                  v-show="form.fourthChoose !== ''"
                >
                  <div>
                    {{ $t("vue_label_report_superiortothisrelationship") }}
                  </div>
                  <div class="all_radios">
                    <el-radio
                      v-model="form.relationship4"
                      :label="index"
                      v-for="(item, index) in words"
                      :key="index"
                      @change="changeWord4"
                      >{{ item }}</el-radio
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getObjectList, getRelateObjectList } from "../api";

export default {
  data() {
    return {
      radio: 1,
      form: {
        type: "1",
        firstChoose: "", // 第一个对象的id
        secondChoose: "", // 第二个对象的fieldid
        thirdChoose: "", // 第三个对象的fieldid
        fourthChoose: "", // 第四个对象的fieldid
        relationship2: 0,
        relationship3: 0,
        relationship4: 0,
      },
      // 搜索无结果时显示此数组
      searchNullArr: [
        {
          labelname: this.$i18n.t("vue_label_report_nosearchresults"),
          disabled: true,
        },
      ],
      firstChooses: [],
      secondChooses: [
        {
          label: this.$i18n.t("label.data.standobject"),
          options: [],
        },
        {
          label: this.$i18n.t("label.customobjects"),
          options: [],
        },
      ],
      thirdChooses: [
        {
          label: this.$i18n.t("label.data.standobject"),
          options: [],
        },
        {
          label: this.$i18n.t("label.customobjects"),
          options: [],
        },
      ],
      fourthChooses: [
        {
          label: this.$i18n.t("label.data.standobject"),
          options: [],
        },
        {
          label: this.$i18n.t("label.customobjects"),
          options: [],
        },
      ],
      firstId: "", // 第二个对象的id
      secondId: "", // 第三个对象的id
      thirdId: "", // 第四个对象的id
      seriesImg: "chuan2",
      words: [
        this.$i18n.t("vue_label_report_notiec_selectone"),
        this.$i18n.t("vue_label_report_mayormaynothave"),
      ],
      objId: "",
      // 搜索框的值
      searchVal: "",
      isUseTempsearchStatus: false, //是否使用临时的搜索的数据展示在下方
      /**
       * 临时的搜索出来的数据（搜索框使用）
       */
      TempsearchArr1: [],
      TempsearchArr2: [],
      TempsearchArr3: [],
      TempsearchArr4: [],
      searchNumStatus: 0, //当前选中的是第几个搜索框
      taskEventFileds: [
        "ffe2014000032890C8YD",
        "ffe2014061332890a8YD",
        "ffe4102000032890C8YD",
        "ffe4102061332890a8YD",
      ], // 任务、事件字段的相关项
    };
  },
  computed: {
    // 所选字段是否属于任务事件字段，从二级对象开始判断，若选择字段为任务事件的相关项字段，则不可选择下一级对象
    isTaskEventFiled: function () {
      return (filedId) => {
        if (this.taskEventFileds.indexOf(filedId) > -1) return true;
        return false;
      };
    },
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("label.visualpage.btype"));
    this.getObjectLists();
  },
  methods: {
    // 清空方法
    getClearValue(value){
      if(value=='first'){
        this.firstId="";
        this.secondId=""
        this.thirdId=""
        this.$set(this.form,'firstChoose',"")
        this.$set(this.form,'secondChoose',"")
        this.$set(this.form,'thirdChoose',"")
      }else if(value=='second'){
        this.secondId=""
        this.thirdId=""
        this.$set(this.form,'secondChoose',"")
        this.$set(this.form,'thirdChoose',"")
      }else if(value=='third'){
        this.thirdId=""
        this.$set(this.form,'thirdChoose',"")
      }
    },
    // 获取对象列表
    async getObjectLists() {
      let params = {
        searchKeyWord: "",
      };
      let dataObject = await getObjectList(params);
      let data = dataObject.data;
      this.firstChooses = data;
    },
    // 点击第一个选择
    async clickFirst(index) {
      this.form.secondChoose = "";
      this.form.thirdChoose = "";
      this.form.fourthChoose = "";
      this.seriesImg = "chuan2";
      if (index !== "") {
        this.objId = this.form.firstChoose;
        // 获取相关对象列表
        let params1 = {
          searchKeyWord: "",
          objId: this.objId,
        };
        let dataObject = await getRelateObjectList(params1);
        let data = dataObject.data;
        this.secondChooses = [
          {
            label: this.$i18n.t("label.data.standobject"),
            options: [],
          },
          {
            label: this.$i18n.t("label.customobjects"),
            options: [],
          },
        ];
        data.map((item) => {
          if (item.objecttype == "2") {
            this.secondChooses[0].options.push(item);
          } else {
            this.secondChooses[1].options.push(item);
          }
        });
      } else {
        this.form.firstChoose = "";
      }
    },
    // 点击第二个选择
    async clickSecond(index) {
      this.form.thirdChoose = "";
      this.form.fourthChoose = "";
      if (this.form.secondChoose == "") {
        this.seriesImg = "chuan2";
      } else {
        if (this.form.relationship2 == 0) {
          this.seriesImg = "chuan3";
        } else {
          this.seriesImg = "chuan4";
        }
      }
      if (index !== "") {
        // 根据v-model绑定的fieldid获取该条数据对应的id
        this.secondChooses.map((item) => {
          item.options.map((item1) => {
            if (item1.fieldid == index) {
              this.firstId = item1.id;
            }
          });
        });
        // 若所选字段为任务、事件字段的相关项，则不需再进行请求下一数据源的数据。
        if (this.taskEventFileds.indexOf(index) > -1) return;
        let params = {
          searchKeyWord: "",
          objId: this.firstId,
        };
        let dataObject = await getRelateObjectList(params);
        let data = dataObject.data;
        this.thirdChooses = [
          {
            label: this.$i18n.t("label.data.standobject"),
            options: [],
          },
          {
            label: this.$i18n.t("label.customobjects"),
            options: [],
          },
        ];
        data.map((item) => {
          if (item.objecttype == "2") {
            this.thirdChooses[0].options.push(item);
          } else {
            this.thirdChooses[1].options.push(item);
          }
        });
      } else {
        this.form.secondChoose = "";
        this.firstId = "";
      }
    },
    // 点击第三个选择
    async clickThird(index) {
      this.form.fourthChoose = "";
      if (this.form.thirdChoose == "") {
        if (this.form.relationship2 == 0) {
          this.seriesImg = "chuan3";
        } else {
          this.seriesImg = "chuan4";
        }
      } else {
        if (this.form.relationship2 == 0) {
          if (this.form.relationship3 == 0) {
            this.seriesImg = "chuan5";
          } else {
            this.seriesImg = "chuan6";
          }
        } else {
          this.form.relationship3 = 1;
          this.seriesImg = "chuan7";
        }
      }
      if (index !== "") {
        // 根据v-model绑定的fieldid获取该条数据对应的id
        this.thirdChooses.map((item) => {
          item.options.map((item1) => {
            if (item1.fieldid == index) {
              this.secondId = item1.id;
            }
          });
        });
        if (this.taskEventFileds.indexOf(index) > -1) return;
        // 获取相关对象列表
        let params = {
          searchKeyWord: "",
          objId: this.secondId,
        };
        let dataObject = await getRelateObjectList(params);
        let data = dataObject.data;
        this.fourthChooses = [
          {
            label: this.$i18n.t("label.data.standobject"),
            options: [],
          },
          {
            label: this.$i18n.t("label.customobjects"),
            options: [],
          },
        ];
        data.map((item) => {
          if (item.objecttype == "2") {
            this.fourthChooses[0].options.push(item);
          } else {
            this.fourthChooses[1].options.push(item);
          }
        });
        this.secondAssociated = index;
      } else {
        this.form.thirdChoose = "";
        this.secondId = "";
      }
    },
    // 点击第四个选择
    clickFourth(index) {
      if (this.form.thirdChoose == "") {
        if (this.form.relationship2 == 0) {
          if (this.form.relationship3 == 0) {
            this.seriesImg = "chuan5";
          } else {
            this.seriesImg = "chuan6";
          }
        } else {
          this.form.relationship3 = 1;
          this.seriesImg = "chuan7";
        }
      } else {
        if (this.form.relationship2 == 0) {
          if (this.form.relationship3 == 0) {
            if (this.form.relationship4 == 0) {
              this.seriesImg = "chuan8";
            } else {
              this.seriesImg = "chuan9";
            }
          } else {
            this.form.relationship4 = 1;
            this.seriesImg = "chuan10";
          }
        } else {
          this.form.relationship4 = 1;
          this.seriesImg = "chuan11";
        }
      }
      if (index !== "") {
        // 根据v-model绑定的fieldid获取该条数据对应的id
        this.fourthChooses.map((item) => {
          item.options.map((item1) => {
            if (item1.fieldid == index) {
              this.thirdId = item1.id;
            }
          });
        });
      } else {
        this.form.fourthChoose = "";
        this.thirdId = "";
      }
    },
    // 切换第二个选择的文字
    changeWord2(index) {
      if (this.form.fourthChoose == "") {
        if (this.form.thirdChoose == "") {
          if (index == 0) {
            this.seriesImg = "chuan3";
          } else {
            this.seriesImg = "chuan4";
          }
        } else {
          if (index == 0) {
            if (this.form.relationship3 == 0) {
              this.seriesImg = "chuan5";
            } else {
              this.seriesImg = "chuan6";
            }
          } else {
            this.form.relationship3 = 1;
            this.seriesImg = "chuan7";
          }
        }
      } else {
        if (index == 0) {
          if (this.form.relationship3 == 0) {
            if (this.form.relationship4 == 0) {
              this.seriesImg = "chuan8";
            } else {
              this.seriesImg = "chuan9";
            }
          } else {
            this.form.relationship4 = 1;
            this.seriesImg = "chuan10";
          }
        } else {
          this.form.relationship3 = 1;
          this.form.relationship4 = 1;
          this.seriesImg = "chuan11";
        }
      }
    },
    // 切换第三个选择的文字
    changeWord3(index) {
      if (this.form.fourthChoose == "") {
        if (this.form.relationship2 == 0) {
          if (index == 0) {
            this.seriesImg = "chuan5";
          } else {
            this.seriesImg = "chuan6";
          }
        } else {
          this.form.relationship3 = 1;
          this.seriesImg = "chuan7";
        }
      } else {
        if (this.form.relationship2 == 0) {
          if (index == 0) {
            if (this.form.relationship4 == 0) {
              this.seriesImg = "chuan8";
            } else {
              this.seriesImg = "chuan9";
            }
          } else {
            this.form.relationship4 = 1;
            this.seriesImg = "chuan10";
          }
        } else {
          this.form.relationship3 = 1;
          this.form.relationship4 = 1;
          this.seriesImg = "chuan11";
        }
      }
    },
    // 切换第四个选择的文字
    changeWord4(index) {
      if (this.form.relationship2 == 0) {
        if (this.form.relationship3 == 0) {
          if (index == 0) {
            this.seriesImg = "chuan8";
          } else {
            this.seriesImg = "chuan9";
          }
        } else {
          this.form.relationship4 = 1;
          this.seriesImg = "chuan10";
        }
      } else {
        this.form.relationship4 = 1;
        this.seriesImg = "chuan11";
      }
    },
    // 点击下一步
    clickNext() {
      let allObjects = {
        objecta: this.form.firstChoose,
        objectb: this.firstId,
        optionb: this.form.relationship2,
        bfindid: this.form.secondChoose,
        objectc: this.secondId,
        optionc: this.form.relationship3,
        cfindid: this.form.thirdChoose,
        objectd: this.thirdId,
        optiond: this.form.relationship4,
        dfindid: this.form.fourthChoose,
        objecttype: this.form.type,
      };
      // 将新建时从数据源带来的数据存储进sessionStorage中
      let sourceData = {
        allObjects: allObjects,
        runTo: false,
      };

      sessionStorage.setItem("sourceData", JSON.stringify(sourceData));
      this.$router.push({
        path: `/reportObject/addReport/edit`,
        query: {},
      });
    },
    // 点击取消
    clickCancel() {
      this.$router.go(-1);
    },
    // 输入框值改变事件
    searchValChangeEvent(val) {
      // val是当前搜索的参数
      // val = val.toUpperCase();
      let tmpArr = [];
      switch (this.searchNumStatus) {
        case 1:
          tmpArr = this.firstChooses.filter((item) => {
            return item.labelname.indexOf(val) > -1;
          });
          // 如果没有搜索出数据即显示搜索框(即使用临时的数组)
          if (tmpArr.length < 1) {
            // 如果没有数据，就使用模拟的无结果数据
            this.isUseTempsearchStatus = true;
            tmpArr = this.searchNullArr;
            this.TempsearchArr1 = tmpArr;
          } else {
            this.isUseTempsearchStatus = true;
            this.TempsearchArr1 = tmpArr;
          }
          break;
        case 2:
          tmpArr = JSON.parse(JSON.stringify(this.secondChooses));
          // 这个是搜索时匹配出的数据(给tmpArr动态赋值)
          tmpArr.forEach((item, index) => {
            tmpArr[index].options = item.options.filter((itemx) => {
              return itemx.labelname.indexOf(val) > -1;
            });
          });
          // 如果数组中没有数据，即显示搜索框 ，可以继续搜索
          if (tmpArr[0].options.length < 1 && tmpArr[1].options.length < 1) {
            this.isUseTempsearchStatus = true;
            tmpArr = [
              {
                label: this.$i18n.t("label.data.standobject"),
                options: [
                  {
                    fieldlabel: this.$i18n.t("label_json_string_null"),
                    labelname: this.$i18n.t("vue_label_report_nosearchresults"),
                    disabled: true,
                  },
                ],
              },
              {
                label: this.$i18n.t("label.customobjects"),
                options: [
                  {
                    fieldlabel: this.$i18n.t("label_json_string_null"),
                    labelname: this.$i18n.t("vue_label_report_nosearchresults"),
                    disabled: true,
                  },
                ],
              },
            ];
            this.TempsearchArr2 = tmpArr;
          } else {
            this.isUseTempsearchStatus = true;
            this.TempsearchArr2 = tmpArr;
          }
          break;
        case 3:
          tmpArr = JSON.parse(JSON.stringify(this.thirdChooses));
          tmpArr.forEach((item, index) => {
            tmpArr[index].options = item.options.filter((itemx) => {
              return itemx.labelname.indexOf(val) > -1;
            });
          });
          if (tmpArr[0].options.length < 1 && tmpArr[1].options.length < 1) {
            this.isUseTempsearchStatus = true;
            tmpArr = [
              {
                label: this.$i18n.t("label.data.standobject"),
                options: [
                  {
                    fieldlabel: this.$i18n.t("label_json_string_null"),
                    labelname: this.$i18n.t("vue_label_report_nosearchresults"),
                    disabled: true,
                  },
                ],
              },
              {
                label: this.$i18n.t("label.customobjects"),
                options: [
                  {
                    fieldlabel: this.$i18n.t("label_json_string_null"),
                    labelname: this.$i18n.t("vue_label_report_nosearchresults"),
                    disabled: true,
                  },
                ],
              },
            ];
            this.TempsearchArr3 = tmpArr;
          } else {
            this.isUseTempsearchStatus = true;
            this.TempsearchArr3 = tmpArr;
          }

          break;
        case 4:
          tmpArr = JSON.parse(JSON.stringify(this.fourthChooses));
          this.fourthChooses.forEach((item, index) => {
            tmpArr[index].options = item.options.filter((itemx) => {
              return itemx.labelname.indexOf(val) > -1;
            });
          });
          // this.TempsearchArr = tmpArr;
          // // 如果数组中没有数据，即显示搜索框 ，可以继续搜索
          // tmpArr.forEach((item) => {
          //   if (item.options.length > 0) {
          //     if (!this.isUseTempsearchStatus) {
          //       this.isUseTempsearchStatus = true;
          //     } else {
          //       this.isUseTempsearchStatus = false;
          //     }
          //   }
          // });

          if (tmpArr[0].options.length < 1 && tmpArr[1].options.length < 1) {
            this.isUseTempsearchStatus = true;

            tmpArr = [
              {
                label: this.$i18n.t("label.data.standobject"),
                options: [
                  {
                    fieldlabel: this.$i18n.t("label_json_string_null"),
                    labelname: this.$i18n.t("vue_label_report_nosearchresults"),
                    disabled: true,
                  },
                ],
              },
              {
                label: this.$i18n.t("label.customobjects"),
                options: [
                  {
                    fieldlabel: this.$i18n.t("label_json_string_null"),
                    labelname: this.$i18n.t("vue_label_report_nosearchresults"),
                    disabled: true,
                  },
                ],
              },
            ];
            this.TempsearchArr4 = tmpArr;
          } else {
            this.isUseTempsearchStatus = true;
            this.TempsearchArr4 = tmpArr;
          }
          break;
      }
    },
    // 搜索框选中赋值当前选中第几个
    focusStatus(searchNumStatus) {
      this.searchNumStatus = searchNumStatus;
    },
    // 点击不同的select让其搜索框中的数据清空
    selectClick() {
      this.isUseTempsearchStatus = false;
      this.searchVal = "";
    },
  },
  beforeDestroy() {
    this.$store.commit("setDropdownData", this.form);
  },
};
</script>

<style lang="scss" scoped>
.searchDataSource {
  box-sizing: border-box;
  ::v-deep .el-input__inner {
    border: 1px solid #dcdcdc;
    height: 40px;
  }
}
.search-icon {
  margin-left: -30px;
}
.dataSource {
  height: 100%;
  font-size: 12px !important;
  color: #080707 !important;
  .dataSource_top {
    height: 61px;
    padding: 20px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-select .el-input .el-input__inner:focus {
      border-color: #e5851a;
    }
    .left_title {
      display: flex;
      align-items: center;
      .title_word {
        color: #222222;
        margin-right: 30px;
      }
      .all_types {
        ::v-deep .el-radio {
          .el-radio__input.is-checked .el-radio__inner {
            border-color: #006dcc;
            background: #006dcc;
          }
        }
      }
    }
    .right_butts {
      display: flex;
    }
  }
  .dataSource_content {
    position: relative;
    height: calc(100% - 61px);
    ::v-deep .top_figure {
      width: 350px;
      height: 250px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        // max-width: 60%;
        // max-height: 60%;
        width: 120px;
        height: 120px;
      }
      ::v-deep .el-select .el-input__inner:focus {
        border: 1px solid #e5851a;
      }
    }

    .choose_selects {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .choose_selects_div {
        height: 100%;
        display: flex;
        align-items: center;
        overflow: auto;
        .choose_selects_center {
          display: flex;
          align-items: flex-start;
          .each_select {
            margin-left: 20px;
            position: relative;
            .second_option {
              width: 312px;
              display: flex;
              flex-direction: column;
              position: relative;
              .activeSelect {
                ::v-deep .el-input__inner {
                  border: 1px solid #e5851a;
                  :focus {
                    border: 1px solid #e5851a;
                  }
                }
              }
            }
            .right_line {
              position: absolute;
              background-color: #e5851a;
              height: 1px;
              left: 158px;
              right: -21px;
              top: 15px;
            }
          }
          .each_select:first-child {
            ::v-deep .el-input__inner {
              background: #e5851a;
              border: 1px solid #e5851a;
              border-radius: 4px;
              color: #ffffff;
            }
            ::v-deep .el-select .el-input .el-select__caret {
              color: #ffffff;
            }
            // ::v-deep .el-select .el-input .el-select__caret::before{
            //    content:'';
            //    width:0;
            //    height:0;
            //    border:8px solid #FFFF;
            //    border-top-color: transparent;
            //    border-left-color: transparent;;
            //    border-right-color: transparent;;
            //    position: relative;
            //    top: -12px;
            // }
            ::v-deep input::-webkit-input-placeholder {
              color: #ffffff;
            }
            ::v-deep input::-moz-input-placeholder {
              color: #ffffff;
            }
            ::v-deep input::-ms-input-placeholder {
              color: #ffffff;
            }
          }
        }
      }
    }
    ::v-deep .el-select {
      width: 158px;
      height: 32px;
      .el-input__inner,
      .el-input__icon {
        height: 32px;
        line-height: 32px;
        color: #1f1b1b;
      }
    }
    ::v-deep .choose_relationship {
      min-height: 104px;
      margin-top: 5px;
      padding: 9px 10px;
      width: 312px;
      background: #ffffff;
      border: 1px dashed #999999;
      border-radius: 4px;
      color: #080707;
      text-align: left;
      .all_radios {
        min-height: 38px;
        margin-top: 6px;
        .el-radio,
        .el-radio__input {
          white-space: pre-wrap; //保留空白符序列，但是正常地进行换行
          margin-top: 4px;
        }
        ::v-deep .el-radio {
          margin-bottom: 8px;
          .el-radio__input.is-checked .el-radio__inner {
            border-color: #006dcc;
            background: #006dcc;
          }
        }
      }
    }
  }
}
::v-deep .el-radio__label {
  color: #080707 !important;
  font-size: 12px !important;
}
</style>

<style>
.el-select-dropdown__list {
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
}
</style>
